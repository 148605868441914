import React from 'react'

import { graphql } from 'gatsby'
import SEO from '../components/seo'

import Categories from '../components/products/categories';
import ImageHeadline from '../components/home/ImageHeadline';
import LocaleManager from '../components/LocaleManager';
import { FormattedMessage } from 'react-intl';

const Products = ({ data, pageContext: { locale } }) => (
  <LocaleManager locale={locale}>
    <FormattedMessage id="products.title">
      { translation => <SEO title={translation} description={data.description.translation} keywords={['belt', 'products']} lang={locale} /> }
    </FormattedMessage>
    <div className="content extra-top">
      <Categories categoriesData={data.categoryData.categories} />
    </div>
    <ImageHeadline gatsbyImage={ data.bottomBg.childImageSharp.fluid } />
  </LocaleManager>
)

export const query = graphql`
  query($locale: String) {
    description: contentfulTranslation(key: { eq: "products-description" }, node_locale: { eq: $locale}) {
      translation
    }
    bottomBg: file(relativePath: { eq: "about-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    categoryData: allContentfulCategory(filter: {node_locale: {eq: $locale}}) {
      categories: edges {
        category: node {
          title {
            title
          }
          id
          categoryDescription {
            categoryDescription
          }
          products: product {
            id
            price
            outOfStock
            newPrice {
              sek
              eur
              usd
            }
            discount
            fullPrice {
              sek
              eur
              usd
            }
            slug
            category {
              title {
                title
              }
            }
            productDescription {
              productDescription
            }
            image {
              fixed(width: 350) {
                ...GatsbyContentfulFixed
              }
            }
            productName {
              productName
            }
          }
        }
      }
    }
  }
`;

export default Products
