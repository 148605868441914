import React from 'react'
import { Link, Element } from 'react-scroll';
import styled from 'styled-components';

import './categories.scss'
import ProductPreview from '../productPreview'
import { colors } from '../../styling/constants';

const Headline = styled.h3`
  color: ${colors.darkGray};
  margin-bottom: 0.5rem;
`

const Categories = ({ categoriesData }) => (
  <div className="category-wrapper">
    <div className="category-nav">
      <ul>
        { categoriesData.map(({category}) => (
          <li key={category.id}>
            <Link to={category.id} smooth={true} offset={-30} duration={500}>{category.title.title}</Link>
          </li>
        ))}
      </ul>
    </div>
    <div className="category-container">
      { categoriesData.map(({ category }) =>
        <Element name={category.id} className="category" key={category.id}>
          <Headline>{ category.title.title }</Headline>
          <p>{ category.categoryDescription.categoryDescription }</p>
          <div className="divider"></div>
          <div className="product-showcase">
            { category.products && category.products.map((product) => (
              <div key={product.id}>
                <ProductPreview product={product} key={product.id} />
              </div>
            ))}
          </div>
        </Element>
      )}
    </div>
  </div>
)

export default Categories
